
import { defineComponent, watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { useWteEventList } from '@/composables/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { RankingPrizeStatus } from '@/interfaces';
import { usePage } from '@/composables/page';
import { useKeyword } from '@/composables';
import { DEFAULT_PAGE_SIZE } from '@/services/api';

const STATUS_TRANSLATE = {
  scheduled: '排程中',
  started: '進行中',
  ended: '已結束'
};

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: '狀態',
    placeholder: '請選擇',
    options: [
      {
        label: '排程中',
        value: 'scheduled'
      },
      {
        label: '進行中',
        value: 'started'
      },
      {
        label: '已結束',
        value: 'ended'
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const status = ref<RankingPrizeStatus>();
    const dateRange = ref(null);
    const eventStartedAt = ref('');
    const eventEndedAt = ref('');
    const statusIndex = ref(undefined);

    const { data } = useWteEventList({ page, pageSize, eventStartedAt, eventEndedAt, status, eventId: keyword });

    const handleFilterChange = (event: FilterEvent) => {
      status.value = event[0] as RankingPrizeStatus;
    };

    const handleDateChange = () => {
      if (!dateRange.value) {
        eventStartedAt.value = '';
        eventEndedAt.value = '';
        return;
      }
      eventStartedAt.value = formatISOString(dateRange.value[0]);
      eventEndedAt.value = formatISOString(dateRange.value[1]);
    };

    watch([eventStartedAt, eventEndedAt], ([eventStartedAt, eventEndedAt]) => {
      router.push({ query: { ...route.query, eventStartedAt, eventEndedAt } });
    });

    watch(
      () => route.query,
      (query) => {
        eventStartedAt.value = query.eventStartedAt as string;
        eventEndedAt.value = query.eventEndedAt as string;

        if (eventStartedAt.value && eventEndedAt.value) {
          dateRange.value = [eventStartedAt.value, eventEndedAt.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    return {
      stage: process.env.VUE_APP_STAGE,
      STATUS_TRANSLATE,
      page,
      data,
      keyword,
      statusIndex,
      FILTER_OPTIONS,
      dateRange,
      setPageQuery,
      formatLocalTime,
      handleDateChange,
      handleFilterChange
    };
  }
});
